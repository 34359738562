import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Include non-visual information`}</h3>
    <p>
  {`${props.visual.replace(/^\w/, c => c.toUpperCase())}s`} can create important context for users,
  but{" "}
  <a href="/foundation/accessibility/" aria-label="accessibility">
    not everyone sees the {props.visual}
  </a>
  . So make sure everything necessary is presented in a non-visual form.
    </p>
    <>
  {props.version !== 2 && <p>
      This can mean including the name of the {props.associated} with the {props.visual} so anyone
      who doesn't see the {props.visual} still gets the name. But if the {props.visual} is present
      next to some text with its name, make sure to hide the {props.visual} from screen readers.
    </p>}
    </>
    <>
  {props.version === 2 && <p>
      By default, the {props.visual} comes with alternative text that's the same as the name of the{" "}
      {props.associated}. Check to make sure that this information is meaningful and not
      unnecessarily repeated.
    </p>}
    </>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      