import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Illustration`}</h1>
    <p>{`To implement Illustration component into your project you’ll need to add the import:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Illustration from "@kiwicom/orbit-components/lib/Illustration";
`}</code></pre>
    <p>{`After adding import into your project you can use it simply like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Illustration name="Accommodation" size="small" />
`}</code></pre>
    <h2>{`Props`}</h2>
    <p>{`The table below contains all types of props available in the Illustration component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`alt`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional property for passing own `}<inlineCode parentName="td">{`alt`}</inlineCode>{` attribute to the DOM image element. By default, an empty string is used.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dataTest`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional prop for testing purposes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Set `}<inlineCode parentName="td">{`id`}</inlineCode>{` for `}<inlineCode parentName="td">{`Illustration`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`name`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "#enum"
            }}><inlineCode parentName="a">{`enum`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Name for the displayed illustration.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "#enum"
            }}><inlineCode parentName="a">{`enum`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"medium"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The size of the Illustration.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`spaceAfter`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`enum`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Additional `}<inlineCode parentName="td">{`margin-bottom`}</inlineCode>{` after component.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`role`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`img\\|presentation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"presentation"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional property for a role attribute.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`enum`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`size`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`spaceAfter`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Accommodation"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"extraSmall"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"none"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"AirHelp"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"small"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"smallest"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"AirportTransport"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"medium"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"small"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"AirportTransportTaxi"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"large"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"normal"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"AirportShuttle"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"display"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"medium"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Ambulance"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"large"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"AppQRCode"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"largest"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"BaggageDrop"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Boarding"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"BoardingPass"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"BusinessTravel"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"CabinBaggage"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"CompassCollectPoints"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"CompassDemoted"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"CompassEmailAdventurer"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"CompassEmailCaptain"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"CompassEmailPromoted"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"CompassEmailPromotedCaptain"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"CompassEmailScout"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"CompassPoints"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Chatbot"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Cancelled"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"CompassTravelPlan"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"CompassSaveOnBooking"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Damage"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"DepartureBoard"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"DepartureBoardBadge"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"DepartureBoardLounge"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"DesktopSearch"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"EnjoyApp"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"EVisa"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Error"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Error404"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"FastTrack"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"FareLock"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"FareLockSuccess"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"FastTrackMan"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Feedback"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"FlexibleDates"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"FlightChange"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"FlightDisruptions"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Help"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Improve"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Insurance"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"InviteAFriend"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"GroundTransport404"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Login"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Lounge"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Mailbox"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Meal"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"MobileApp"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Money"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"MusicalInstruments"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"NetVerify"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"NoBookings"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"NoFavoriteFlights"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"NoFlightChange"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Nomad"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"NomadNeutral"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"NoNotification"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"NoResults"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Offline"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"OnlineCheckIn"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"OpenSearch"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Parking"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"PassportUpdate"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Pets"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"PlaceholderAirport"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"PlaceholderDestination"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"PlaceholderHotel"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"PlaceholderTours"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"PlaneAndMoney"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"PlaneDelayed"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"PriceChange"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"PriorityBoarding"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Rating"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"ReferAFriend"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"RentalCar"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Seating"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"SpecialAssistance"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"SportsEquipment"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Success"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Time"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"TicketFlexi"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"TimelineBoarding"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"TimelineDropBaggage"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"TimelineLeave"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"TimelinePick"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Tours"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Train"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"TransportBus"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"TransportTaxi"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"Wheelchair"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"WomanWithPhone"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      