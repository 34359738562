const handleKeyDown = (onAction, action) => ev => {
  if (ev.code === "Enter") {
    if (onAction) {
      onAction(ev);
    }
    if (action) {
      action(ev);
    }
  } else if (ev.code === "Space") {
    ev.preventDefault();
    if (onAction) {
      onAction(ev);
    }
    if (action) {
      action(ev);
    }
  }
};
export default handleKeyDown;