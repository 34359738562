import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`ChoiceGroup`}</h1>
    <p>{`To implement ChoiceGroup component into your project you’ll need to add the import:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import ChoiceGroup from "@kiwicom/orbit-components/lib/ChoiceGroup";
import Radio from "@kiwicom/orbit-components/lib/Radio";
`}</code></pre>
    <p>{`After adding import into your project you can use it simply like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<ChoiceGroup label="What was the reason for your cancellation?">
  <Radio label="Reason one" value="one" />
  <Radio label="Reason two" value="two" />
  <Radio label="Reason three" value="three" />
</ChoiceGroup>
`}</code></pre>
    <h2>{`Props`}</h2>
    <p>{`Table below contains all types of the props available in the ChoiceGroup component.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dataTest`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional prop for testing purposes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Set `}<inlineCode parentName="td">{`id`}</inlineCode>{` for `}<inlineCode parentName="td">{`ChoiceGroup`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`children`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.Node `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`(args) => React.Node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The content of the ChoiceGroup, normally `}<strong parentName="td">{`Radio`}</strong>{` or `}<strong parentName="td">{`Checkbox`}</strong>{`. Pass a function for advanced usage, see “Render prop” in Storybook for an example.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`error`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Translation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The error to display beneath the ChoiceGroup. Also, the Checkboxes/Radios will turn red when you pass some value.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Translation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Heading text of the ChoiceGroup`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`labelAs`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "#enum"
            }}><inlineCode parentName="a">{`enum`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"div"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The element used to render the label into.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`labelSize`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "#enum"
            }}><inlineCode parentName="a">{`enum`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"normal"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The size type of Heading.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><strong parentName="td">{`onChange`}</strong></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`event => void \\| Promise`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function for handling onChange event. `}<a parentName="td" {...{
              "href": "#functional-specs"
            }}>{`See Functional specs`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`filter`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Changes visual appearance of child components, to contain background on hover and updates padding.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onOnlySelection`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`(event, {value: string, label: string}) => void \\| Promise<any>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function for handling onOnlySelection, read more in Functional specs.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onlySelectionText`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Translation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Property for passing translation string when you want to use the `}<inlineCode parentName="td">{`onOnlySelection`}</inlineCode>{` callback.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`enum`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`labelElement`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`labelSize`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"h2"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"normal"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"h3"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"large"`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"h4"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"h5"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`"h6"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3>{`Passing a function as `}<inlineCode parentName="h3">{`children`}</inlineCode></h3>
    <p>{`If you need more control over how to render ChoiceGroup, for example using `}<a parentName="p" {...{
        "href": "https://github.com/bvaughn/react-window"
      }}><inlineCode parentName="a">{`react-window`}</inlineCode></a>{`, you can pass a function to `}<inlineCode parentName="p">{`children`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`React.Node`}</inlineCode>{`, which receives an object containing the following properties:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Container`}</inlineCode>{` is the inner container where Radio/Checkbox elements are placed`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Item`}</inlineCode>{` is the component that should wrap Radio/Checkbox elements`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`spacing`}</inlineCode>{` is the spacing between items, which you need to apply yourself`}</li>
    </ul>
    <p>{`This is a barebones example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<ChoiceGroup onChange={ev => doSomething(ev)}>
  {({ Container, Item, spacing }) => (
    <Container style={{ display: "flex", flexDirection: "column", gap: spacing }}>
      <Item>
        <Radio label="Reason one" value="two" />
      </Item>
      <Item>
        <Radio label="Reason two" value="two" />
      </Item>
      <Item>
        <Radio label="Reason three" value="three" />
      </Item>
    </Container>
  )}
</ChoiceGroup>
`}</code></pre>
    <p>{`For more realistic usage you can check out the “Render prop” example in Storybook.`}</p>
    <h2>{`Functional specs`}</h2>
    <ul>
      <li parentName="ul">{`onChange props in `}<inlineCode parentName="li">{`<Radio />`}</inlineCode>{` or `}<inlineCode parentName="li">{`<Checkbox />`}</inlineCode>{` will be overridden by internal onChange function`}</li>
      <li parentName="ul">{`If you want to handle selecting field, pass `}<inlineCode parentName="li">{`onChange`}</inlineCode>{` to `}<inlineCode parentName="li">{`<ChoiceGroup />`}</inlineCode>{` and it will be always triggered when `}<inlineCode parentName="li">{`<Radio />`}</inlineCode>{` or `}<inlineCode parentName="li">{`<Checkbox />`}</inlineCode>{` should change`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`onChange`}</inlineCode>{` will return `}<inlineCode parentName="li">{`SyntheticEvent`}</inlineCode>{` of field that should change`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<ChoiceGroup onChange={ev => doSomething(ev)}>
  <Radio label="Reason one" value="one" />
  <Radio label="Reason two" value="two" />
  <Radio label="Reason three" value="three" />
</ChoiceGroup>
`}</code></pre>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`onOnlySelection`}</inlineCode>{` can be used only when `}<inlineCode parentName="li">{`filter`}</inlineCode>{` prop is used.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`filter`}</inlineCode>{` pattern with `}<inlineCode parentName="li">{`onOnlySelection`}</inlineCode>{` is used where multiple checkboxes in different states are presented to the user, and the user wants to choose only one of them.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`filter`}</inlineCode>{` pattern with `}<inlineCode parentName="li">{`onOnlySelection`}</inlineCode>{` shouldn’t contain radio buttons.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      