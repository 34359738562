import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Toast`}</h1>
    <p>{`The Toast component consists of `}<inlineCode parentName="p">{`ToastRoot`}</inlineCode>{` and `}<inlineCode parentName="p">{`createToast`}</inlineCode>{`/`}<inlineCode parentName="p">{`createToastPromise`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { ToastRoot, createToast } from "@kiwicom/orbit-components/lib/Toast";
`}</code></pre>
    <p>{`It’s better to use ToastRoot once at the root of your application with your other context providers and you can use `}<inlineCode parentName="p">{`createToast`}</inlineCode>{` from anywhere after. The `}<inlineCode parentName="p">{`createToast`}</inlineCode>{` function accepts two arguments. The first is required and is the message to be displayed on the toast. The second is an object. This object contains the `}<inlineCode parentName="p">{`icon`}</inlineCode>{` key that receives the icon to be rendered on the toast as well as the `}<inlineCode parentName="p">{`ariaProps`}</inlineCode>{` key that receives another object, with the `}<inlineCode parentName="p">{`role`}</inlineCode>{` and `}<inlineCode parentName="p">{`aria-live`}</inlineCode>{` keys and corresponding attributes as values.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from "react";
import { ToastRoot, createToast } from "@kiwicom/orbit-components/lib/Toast";
import Notification from "@kiwicom/orbit-components/lib/icons/Notification";

const notify = () =>
  createToast("Here is your toast", {
    icon: <Notification />,
    ariaProps: {
      role: "alert",
      "aria-live": "assertive",
    },
  });
const App = () => {
  return (
    <div>
      <button onClick={notify}>Make me a toast</button>
      <ToastRoot />
    </div>
  );
};
`}</code></pre>
    <p>{`You can also use the `}<inlineCode parentName="p">{`createToastPromise`}</inlineCode>{` function. The notification toast will be updated automatically, when the promise is resolved or rejected. The function receives two mandatory arguments and one optional. The first is the promise, the second is the message on the different statuses and the third one controls the icon displayed on each status (or all).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const notify = () =>
  createPromiseToast(
    promise,
    {
      loading: "...Loading",
      success: "Got the data",
      error: "Error when fetching",
    },
    {
      success: {
        icon: <Notification />,
      },
    },
  );
`}</code></pre>
    <h2>{`Props`}</h2>
    <p>{`Table below contains all types of the props available for `}<inlineCode parentName="p">{`ToastRoot`}</inlineCode>{` component`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dataTest`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`optional prop for testing purposes.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Set `}<inlineCode parentName="td">{`id`}</inlineCode>{` for `}<inlineCode parentName="td">{`Toast`}</inlineCode>{` wrapper`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`topOffset`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`top offset for toast container`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`leftOffset`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`left offset for toast container`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`rightOffset`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`right offset for toast container`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`bottomOffset`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`bottom offset for toast container`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`gutter`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`distance between toast notifications`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dismissTimeout`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`5000`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`timeout until toast will be dismissed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`placement`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "#Placement"
            }}><inlineCode parentName="a">{`Placement`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`position for toast container`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Placement`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Placement`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`“top-left”`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`“top-center”`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`“top-right”`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`“bottom-left”`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`“bottom-center”`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`“bottom-right”`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      